<template>
  <div class="newsList" style="min-width: 1900px;">
    <div class="newsList_bg scaleBanner">
      <div class="newsList_bg_name moveTopTitle">新闻中心</div>
    </div>
    <div class="newsList_head moveTopNormal">
      <div class="newsList_head_boder">
        <div
          class="newsList_head_title"
          :class="active == 0 ? ' active' : ''"
          @click="
            active = 0;
            query.page = 1;
            getList();
          "
        >
          新闻资讯
        </div>
        <div
          class="newsList_head_title"
          :class="active == 1 ? ' active' : ''"
          @click="
            active = 1;
            query.page = 1;
            getList();
          "
        >
          媒体资讯
        </div>
        <div
          class="newsList_head_title"
          :class="active == 2 ? ' active' : ''"
          @click="
            active = 2;
            query.page = 1;
            getList();
          "
        >
          行业资讯
        </div>
        <div
          class="newsList_head_title"
          :class="active == 3 ? ' active' : ''"
          @click="
            active = 3;
            query.page = 1;
            getList();
          "
        >
          热点专题
        </div>
      </div>
    </div>
    <div class="newsList_list">
      <div class="newsList_list_table">
        <ul class="newsList_list_table_ul">
          <li
            class="newsList_list_table_ul_li moveTopNormal"
            v-for="item in newsList"
            :key="item.id"
            @click="toDetails(item)"
          >
            <div
              class="newsList_list_table_ul_left"
              style="
                width: 450px;
                height: 230px;
                overflow: hidden;
                margin-right: 40px;
                position: relative;
              "
            >
              <img
                src="../../static/newImg/img_loading.png"
                class="img_loading"
                v-show="imgSuffix"
              />
              <img
                v-if="item.newsCover"
                :src="item.newsCover + imgSuffix"
                style="width: 450px"
              />
              <img
                v-else
                src="../../static/image/home_news.png"
                height="180px"
                width="450px"
              />
            </div>
            <div class="moveRight newsList_list_table_ul_right">
              <div class="newsList_list_table_ul_li_time">
                发布时间：{{ item.year }}-{{ item.day }}
              </div>
              <div class="newsList_list_table_ul_li_title">
                {{
                  item.newsTitle.length > 25
                    ? item.newsTitle.substring(0, 25) + "..."
                    : item.newsTitle
                }}
              </div>
              <div class="newsList_list_table_ul_li_content">
                {{
                  item.newsIntroduction
                    ? item.newsIntroduction.length > 30
                      ? item.newsIntroduction.substring(0, 30) + "..."
                      : item.newsIntroduction
                    : item.title
                }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="newsList_list_right">
        <div class="newsList_list_right_title moveTopNormal">推荐新闻</div>
        <div
          class="newsList_list_right_item moveTopNormal"
          v-for="item in recommendList"
          :key="item.id"
          @click="toDetails(item)"
        >
          <div class="newsList_list_right_item_date">
            发布时间： {{ item.year }}.{{ item.day }}
          </div>
          <div class="newsList_list_right_item_newsTitle">
            {{ item.newsTitle }}
          </div>
        </div>
      </div>
    </div>
    <div class="newsList_page">
      <div class="newsList_pagination">
        <el-pagination
          layout="prev, pager, next"
          :page-size="6"
          :total="query.total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getAccessToken, getSignature, getNewsList } from "@/api/index";
export default {
  name: "newsList",
  data() {
    return {
      imgSuffixTime: null,
      imgSuffix: "?x-oss-process=image/resize,m_lfit,w_300",
      newsList: [],
      recommendList: [{}],
      query: {
        page: 1,
        size: 6,
        total: 0,
      },
      active: 0,
    };
  },
  mounted() {
    this.companyItem = this.$store.getters.getCompany;
    if (this.$route.query.id) {
      this.active = this.$route.query.id;
    }
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = "";
    }, 4000);
    window.scroll(0, 0);
    this.getList(); // 18 文章推荐
    this.getRecommendList();
    this.getWXInfo();
    if (
      /Mobile|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent)
    ) {
      document.getElementsByClassName("newsList")[0].style =
        "min-height: 2800px;";
    }
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp;
        const nonceStr = res.data.noncestr;
        const signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function() {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: "新闻中心", // 分享标题
            desc: "浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工、仓储物流等，推动地方文旅、科研、产教研学、人才培养等多元化产业发展；同时积极探索药、风、光、零碳、能源产业带的同步推进。在集团内循环产业链赋能下，跨界融合部署，遵循国家政策，紧跟国家战略，以“数字经济新农业 乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。 乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。", // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-10-09/0083d16c8d594bd49bbc9bb0fc740783.png", // 分享图标
          };
          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    toDetails(item) {
      const active = this.active;
      const type =
        active == 0
          ? "新闻资讯"
          : active == 1
          ? "媒体资讯"
          : active == 2
          ? "行业资讯"
          : "热点专题";
      this.$router.push({
        path: "/newsListDetail",
        query: { id: item.newsId, type: type, index: active },
      });
    },
    getList() {
      let websiteCategoryId = 18;
      if (this.active == 0) {
        websiteCategoryId = 18;
      }
      if (this.active == 1) {
        websiteCategoryId = 38;
      }
      if (this.active == 2) {
        websiteCategoryId = 35;
      }
      if (this.active == 3) {
        websiteCategoryId = 36;
      }
      let data = {};
      if (this.companyItem.companyId == 3) {
        data = {
          current: this.query.page,
          size: 6,
          websiteId: 3,
          self: 1,
          websiteCategoryId: websiteCategoryId,
        };
      } else {
        data = {
          current: this.query.page,
          size: 6,
          websiteCategoryId: websiteCategoryId,
          self: 1,
          companyId: this.companyItem.companyId,
        };
      }
      getNewsList(data).then(async (res) => {
        const content = res.data.list;
        content.forEach((item) => {
          const updateTime = new Date(item.publishTime);
          item.day =
            updateTime.getDate() > 9
              ? updateTime.getDate()
              : "0" + updateTime.getDate();
          item.year =
            updateTime.getFullYear() +
            "." +
            (updateTime.getMonth() + 1 > 9
              ? updateTime.getMonth() + 1
              : "0" + (updateTime.getMonth() + 1));
          item.title =
            item.newsTitle.length > 30
              ? item.newsTitle.substring(0, 30) + "..."
              : item.newsTitle;
        });
        this.newsList = content;
        this.query.total = res.data.total;
        setTimeout(() => {
          const targetElement = document.querySelectorAll(
            ".newsList_list_table_ul_li"
          );
          targetElement.forEach((val) => {
            if (val.className.indexOf("moveTopNormal moveTopAnimation") == -1) {
              setTimeout(() => {
                val.className += " moveTopNormal moveTopAnimation";
              }, 200);
            }
          });
        }, 200);
      });
    },
    getRecommendList() {
      let data = {};
      if (this.companyItem.companyId == 3) {
        data = {
          current: this.query.page,
          size: 4,
          websiteCategoryId: 39,
          self: 1,
          websiteId: 3,
        };
      } else {
        data = {
          current: this.query.page,
          size: 4,
          websiteCategoryId: 39,
          self: 1,
          companyId: this.$store.getters.getCompany.companyId,
        };
      }
      getNewsList(data).then(async (res) => {
        const content = res.data.list;
        content.forEach((item) => {
          const updateTime = new Date(item.publishTime);
          item.day =
            updateTime.getDate() > 9
              ? updateTime.getDate()
              : "0" + updateTime.getDate();
          item.year =
            updateTime.getFullYear() +
            "." +
            (updateTime.getMonth() + 1 > 9
              ? updateTime.getMonth() + 1
              : "0" + (updateTime.getMonth() + 1));
          item.title =
            item.newsTitle.length > 30
              ? item.newsTitle.substring(0, 30) + "..."
              : item.newsTitle;
        });
        console.log(content);
        this.recommendList = content;
        // setTimeout(() => {
        //   const targetElement = document.querySelectorAll(
        //     ".newsList_list_right_item"
        //   );
        //   targetElement.forEach((val) => {
        //     if (
        //       val.className.indexOf("moveTopNormal moveTopAnimation") == -1
        //     ) {
        //       setTimeout(() => {
        //         val.className += " moveTopNormal moveTopAnimation";
        //       }, 200);
        //     }
        //   });
        // }, 200);
      });
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
  },
  beforeDestroy() {
    clearTimeout(this.imgSuffixTime);
  },
  watch: {
    $route() {
      if (this.$route.query.id) {
        this.active = this.$route.query.id;
      } else {
        this.active = 0;
      }
        this.getList(); // 18 文章推荐
    },
    active() {
      const targetElement = document.querySelectorAll(
        ".newsList_list_table_ul_li"
      );
      targetElement.forEach((val) => {
        val.className = val.className.split(
          " moveTopNormal moveTopAnimation"
        )[0];
        setTimeout(() => {
          val.className += " moveTopNormal moveTopAnimation";
        }, 200);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.newsList_bg {
  height: 560px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-image: url("../../static/image/newsList_bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  .newsList_bg_name {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 56px;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1px;
    font-family: 黑体;
  }
}

.newsList_head {
  width: 100%;

  .newsList_head_boder {
    width: 1600px;
    border-bottom: 1px solid #ededed;
    margin: 0 auto;

    .newsList_head_title {
      padding: 14px 0;
      color: #999;
      border-bottom: 3px solid transparent;
      margin: 0 80px 0 0;
      display: inline-block;
      font-size: 18px;
      line-height: 60px;
      color: #333;
      transition: all 0.5s;
      cursor: pointer;
    }

    .active {
      color: #1e9409;
      border-color: #1e9409;
      font-weight: bold;
    }
  }
}

.newsList_list {
  margin: 40px auto;
  display: flex;
  width: 1600px;
  padding: 0 20px;

  .newsList_list_table {
    width: calc(75% - 50px);
    padding-right: 50px;
    border-right: 1px solid #ededed;

    .newsList_list_table_ul {
      padding: 0;
      margin: 0;
      overflow: hidden;

      .newsList_list_table_ul_left img {
        transition: all 0.3s;
      }

      .newsList_list_table_ul_right {
        width: calc(100% - 450px);
        transition: all 0.3s;
      }

      li {
        margin-top: 20px;
        cursor: pointer;
        list-style-type: none;
        line-height: 62px;
        font-size: 14px;
        font-weight: 400;
        color: #1d1b19;
        border-bottom: 1px dashed #e5e5e5;
        cursor: pointer;
        display: flex;
        padding-bottom: 32px;

        .newsList_list_table_ul_li_title {
          font-size: 24px;
          font-weight: bold;
          color: #252525;
          line-height: 30px;
          transition: all 0.3s;
        }

        .newsList_list_table_ul_li_content {
          margin-top: 30px;
          font-size: 14px;
          font-weight: 400;
          color: #858585;
          line-height: 24px;
          width: 890px;
          overflow: hidden; // 溢出隐藏
          white-space: nowrap; // 强制一行
          text-overflow: ellipsis; // 文字溢出显示省略号
        }

        .newsList_list_table_ul_li_time {
          margin-top: 26px;
          margin-bottom: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #abaeb0;
          line-height: 17px;
        }
      }
    }

    .newsList_list_table_ul li:hover {
      .newsList_list_table_ul_left img {
        transform: scale(1.1);
      }

      .moveRight {
        transform: translateX(5px);
      }

      .newsList_list_table_ul_li_title {
        color: #1e9409;
      }
    }
  }

  .newsList_list_right {
    width: 25%;
    margin-left: 50px;

    .newsList_list_right_title {
      font-size: 18px;
      color: #111;
      font-weight: bold;
    }

    .newsList_list_right_item {
      width: 90%;
      border-bottom: 1px solid #f2f2f2;
      cursor: pointer;

      .newsList_list_right_item_date {
        font-size: 14px;
        color: #888;
        vertical-align: middle;
        margin: 20px 0 10px 0;
      }

      .newsList_list_right_item_newsTitle {
        font-size: 16px;
        color: #333;
        transition: ease 0.5s;
        line-height: 26px;
        max-height: 52px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    .newsList_list_right_item:hover {
      .newsList_list_right_item_newsTitle {
        color: #1e9409;
        font-weight: bold;
      }
    }
  }
}

.newsList_page {
  width: 1600px;
  text-align: center;
  margin: 100px auto 50px auto;

  .newsList_pagination {
    width: calc(75% - 50px);
  }
}

::v-deep .el-pager li.active {
  width: 28px;
  height: 28px;
  background: #354a32;
  border-radius: 2px;
  color: #ffffff;
  line-height: 28px;
}

::v-deep .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  color: #565759;
  line-height: 28px;
  padding: 0;
  margin: 0 4px;
  font-weight: 400;
}

::v-deep .el-pager li.active + li {
  border: 1px solid #e5e5e5;
}
</style>
